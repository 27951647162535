<template>
  <div>
    <show-case :sku="vivalFruitsCards" />
  </div>
</template>

<script>
import ShowCase from "../components/ShowCase.vue";

import VivalFruitsApricotAl from "../assets/Vival/Vfruitsweets/Vival-apricot-almond-chok-180.jpg";
import VivalFruitsOrange from "../assets/Vival/Vfruitsweets/Vival-orange-chok-180.jpg";
import VivalFruitsRange from "../assets/Vival/Vfruitsweets/Vival-assorti-180.jpg";
import VivalFruitsPear from "../assets/Vival/Vfruitsweets/Vival-pear-180.jpg";
import VivalFruitsStrawberry from "../assets/Vival/Vfruitsweets/Vival-straberry-white-180.jpg";
import VivalFruitsMango from "../assets/Vival/Vfruitsweets/Vival-mango-180.jpg";
import VivalFruitsPrune from "../assets/Vival/Vfruitsweets/Vival-prune-180.jpg";
import VivalFruitsPruneW from "../assets/Vival/Vfruitsweets/Vival-plum-walnut-180.jpg";

export default {
  name: "VivalFruits",
  components: {
    ShowCase,
  },
  data() {
    return {
      vivalFruitsCards: [
        {
          picture: VivalFruitsApricotAl,
          text: "ВИВАЛЬ Абрикос с миндалем в шоколадной глазури 180&nbspг",
          id: 1,
          description:
            "Состав: абрикос сушеный (абрикос сушеный без косточки, консервант Е220), шоколадная глазурь (сахар, какао тертое, какао-масло, эквивалент какао-масла (жир растительный), какао-порошок, эмульгаторы (соевый лецитин, Е476), ароматизатор «Ванилин»), ядро миндаля жареное, комплексная пищевая добавка влагоудерживающая «Ваниль» (агент влагоудерживающий Е422, вкусоароматические вещества), регулятор кислотности лимонная кислота, комплексная пищевая добавка глазирователь «Капол» (стабилизатор-гуммиарабик, сахар, патока, пальмовое и кокосовое масло, регулятор кислотности-лимонная кислота, консервант сорбиновая кислота), консервант сорбат калия, ароматизатор «Абрикос».<br>Продукт может содержать косточки или их фрагменты, следы молочного белка, кунжута арахиса, и других орехов.<br>Пищевая ценность в 100 г: Белки - 5,9г, Жиры 17,6г, Углеводы 58,4 г.<br>Энергетическая ценность 406 Ккал.<br>Хранить при температуре от +15°С до +21°С и относительной влажности воздуха не более 75%. Срок годности 9 месяцев.<br>ТУ 9123-008-60458573-15<br>Страна изготовитель: Россия",
        },
        {
          picture: VivalFruitsOrange,
          text: "ВИВАЛЬ Апельсин в шоколадной глазури 180&nbspг",
          id: 2,
          description:
            "Состав: фрукты сушеные (апельсин, ананас, сахар, регулятор кислотности лимонная кислота, консервант Е220), шоколадная глазурь (сахар, какао тертое, какао-масло, эквивалент какао-масла (растительный жир), какао-порошок, эмульгаторы (соевый лецитин, Е476), ароматизатор «Ванилин»), фруктово-ягодная начинка «Лимон» (сахар, лимон (пюре), загуститель пектин, крахмал кукурузный, регулятор кислотности - лимонная кислота, краситель «Лютеин», ароматизатор «Лимон», консервант сорбат калия), заменитель молочного жира (рафинированные дезодорированные растительные масла (пальмовое, подсолнечное, рапсовое, соевое, кокосовое) эмульгатор Е471), пищевые волокна пшеничные, ароматизатор «Апельсин».<br>Продукт может содержать следы кунжута, арахиса и орехов.<br>Пищевая ценность в 100 г: Белки - 3,5г, Жиры 10,5г, Углеводы 75,4 г.<br>Энергетическая ценность 410,1 Ккал.<br>Хранить при температуре от +15°С до +21°С и относительной влажности воздуха не более 75%. Срок годности 9 месяцев.<br>ТУ 9123-008-60458573-15<br>Страна изготовитель: Россия",
        },
        {
          picture: VivalFruitsRange,
          text: "ВИВАЛЬ Ассорти фруктов в шоколадной глазури 180&nbspг",
          id: 3,
          description:
            "Клубника в белой шоколадной глазури<br>Апельсин в шоколадной глазури<br>Манго в шоколадной глазури<br>Пищевая ценность в 100 г: Белки - 2,0 г, Жиры 11,5 г, Углеводы 62,4 г.<br>Энергетическая ценность 361 Ккал.<br>Хранить при температуре от +15°С до +21°С и относительной влажности воздуха не более 75%. Срок годности 9 месяцев.<br>ТУ9123-008-60458573-15<br>Страна изготовитель: Россия",
        },
        {
          picture: VivalFruitsPear,
          text: "ВИВАЛЬ Груша в шоколадной глазури 180&nbspг",
          id: 4,
          description:
            "Состав: фрукты сушеные (груша, гуава, сахар, регулятор кислотности лимонная кислота, консервант Е220), шоколадная глазурь (сахар, какао тертое, какао-масло, растительный жир (эквивалент масла какао), какао-порошок, эмульгатор соевый лецитин, эмульгатор  Е476, ароматизатор ванилин), комплексная пищевая добавка влагоудерживающая  «Ваниль» (агент влагоудерживающий Е422, вкусоароматические вещества), пищевые волокна пшеничные, ароматизатор «Груша», консервант сорбат калия.<br>Продукт может содержать следы молочного белка, кунжута, арахиса и других орехов.<br>Пищевая ценность в 100 г: Белки - 2,2г, Жиры 14,2г, Углеводы 66,4 г.<br>Энергетическая ценность 409 Ккал.<br>Хранить при температуре от +15°С до +21°С и относительной влажности воздуха не более 75%. Срок годности 9 месяцев.<br>ТУ 9123-008-60458573-15<br>Страна изготовитель: Россия",
        },
        {
          picture: VivalFruitsStrawberry,
          text: "ВИВАЛЬ Клубника в белой шоколадной глазури 180&nbspг",
          id: 5,
          description:
            "Состав: клубника сушеная (клубника, сахар, регулятор кислотности лимонная кислота, консервант Е220, краситель кармины), белая шоколадная глазурь (сахар, эквивалент какао-масла (пальмовое масло, масло ши), молоко сухое обезжиренное, какао-масло, молочный жир, эмульгаторы (соевый лецитин,Е476), ароматизатор «Ванильный»), фруктово-ягодная начинка «Клубника» (сахар, клубника, загуститель пектин, крахмал кукурузный, регулятор кислотности лимонная кислота, краситель кармины, ароматизатор «Клубника», консервант сорбат калия), комплексная пищевая добавка влагоудерживающая «Ваниль»(агент влагоудерживающий Е422, вкусоароматические вещества), продукт сублимационной сушки из ягод дробленый «Клубника», ароматизатор «Клубника», регулятор кислотности лимонная кислота.<br>Продукт может содержать следы кунжута, арахиса и орехов.<br>Пищевая ценность в 100 г: Белки - 0,9г, Жиры 10,4г, Углеводы 65,4 г.<br>Энергетическая ценность 363 Ккал.<br>Хранить при температуре от +15°С до +21°С и относительной влажности воздуха не более 75%. Срок годности 9 месяцев.<br>ТУ 9123-008-60458573-15<br>Страна изготовитель: Россия",
        },
        {
          picture: VivalFruitsMango,
          text: "ВИВАЛЬ Манго в шоколадной глазури 180&nbspг",
          id: 6,
          description:
            "Состав: фрукты сушеные (манго, персик, сахар, регулятор кислотности лимонная кислота, консервант Е220), шоколадная глазурь (сахар, какао тертое, какао-масло, растительный жир (эквивалент какао масла), какао-порошок, эмульгаторы (соевый лецитин, Е 476), ароматизатор «Ванилин»), комплексная пищевая добавка влагоудерживающая «Ваниль» (агент влагоудерживающий Е 422, вкусоароматические вещества), пищевые волокна пшеничные, регулятор кислотности лимонная кислота, ароматизатор «Манго», консервант сорбат калия.<br>Продукт может содержать следы молочного белка, кунжута, арахиса и других орехов. Возможно попадание жестких волокон манго.<br>Пищевая ценность в 100 г: Белки - 3,9г, Жиры 14,5г, Углеводы 57,8 г.<br>Энергетическая ценность 387 Ккал.<br>Хранить при температуре от +15°С до +21°С и относительной влажности воздуха не более 75%. Срок годности 9 месяцев.<br>ТУ9123-008-60458573-15<br>Страна изготовитель: Россия",
        },
        {
          picture: VivalFruitsPrune,
          text: "ВИВАЛЬ Слива в шоколадной глазури 180&nbspг",
          id: 7,
          description:
            "Состав: чернослив (сливы сушеные без косточки, масло подсолнечное, консервант сорбат калия), шоколадная глазурь (сахар, какао тертое, какао-масло, растительный жир (эквивалент масла какао), какао-порошок, эмульгатор соевый лецитин, эмульгатор Е 476, ароматизатор ванилин), комплексная пищевая добавка влагоудерживающая «Ваниль» (агент влагоудерживающий Е 422, вкусоароматические вещества).<br>Продукт может содержать фрагменты скорлупы, следы молочного белка, арахиса, кунжута и орехов.<br>Пищевая ценность в 100 г: Белки - 4,0г, Жиры 17,0г, Углеводы 54,0 г.<br>Энергетическая ценность 385 Ккал.<br>Хранить при температуре от +15°С до +21°С и относительной влажности воздуха не более 75%. Срок годности 9 месяцев.<br>ТУ 9123-008-60458573-15<br>Страна изготовитель: Россия",
        },
        {
          picture: VivalFruitsPruneW,
          text: "ВИВАЛЬ Слива с грецким орехом в шоколадной глазури 180&nbspг",
          id: 8,
          description:
            "Состав: чернослив сушеный (сливы сушеные без косточки, масло подсолнечное, консервант сорбат калия), шоколадная глазурь (сахар, какао тертое, какао-масло, эквивалент масла какао (растительный жир), какао-порошок, эмульгаторы (соевый лецитин, Е476), ароматизатор «Ванилин»), ядро грецкого ореха, комплексная пищевая добавка влагоудерживающая «Ваниль» (агент влагоудерживающий Е422, вкусоароматические вещества), комплексная пищевая добавка глазирователь «Капол» (стабилизатор гуммиарабик, сахар, патока, пальмовое и кокосовое масло, регулятор кислотности Е330, консервант сорбиновая кислота).<br>Продукт может содержать косточки или их фрагменты, следы молочного белка,арахиса, кунжута и других орехов.<br>Пищевая ценность в 100 г: Белки - 4,2г, Жиры 17,6г, Углеводы 57,5 г.<br>Энергетическая ценность 399 Ккал.<br>Хранить при температуре от +15°С до +21°С и относительной влажности воздуха не более 75%. Срок годности 9 месяцев.<br>ТУ 9123-008-60458573-15<br>Страна изготовитель: Россия",
        },
      ],
    };
  },
};
</script>
